<!--  -->
<template>
    <!-- <el-tabs>
        <el-tab-pane label="全部项目"> -->
    <div>
        <div >
            <el-switch style="display: block" v-model="chooseItem" active-color="#428bca" inactive-color="firebrick"
                active-text="全部项目" inactive-text="孕婴专项" @change="updateItemShowStatus">

            </el-switch>
            <div class="box">

                <div class="box-item" v-if="chooseItem">
                    <div class="item-first">
                        <div style="color:brown;font-weight:bold;height:40px;font-size: 20px;">
                            <span style="background-color: brown; color:white; border-radius:50%;padding:3px 5px">今</span>
                            <span> 星期{{ day }}</span>
                        </div>
                        <div
                            style="color:black;font-weight:bold;font-size: 28px;font-family:'Times New Roman', Times, serif;">
                            {{ formerday }}至{{ today }}</div>
                    </div>
                </div>
                <div class="box-item" v-if="!chooseItem">
                    <div class="item-first">
                        <div style="color:brown;font-weight:bold;height:40px;font-size: 20px;">
                            <span style="background-color: brown; color:white; border-radius:50%;padding:3px 5px">今</span>
                            <span> 星期{{ day }}</span>
                        </div>
                        <div
                            style="color:black;font-weight:bold;font-size: 28px;font-family:'Times New Roman', Times, serif;">
                            当前孕周：第{{ PregnancyWeek }}周</div>
                        <br>
                        <div
                            style="color:black;font-weight:bold;font-size: 28px;font-family:'Times New Roman', Times, serif;">
                            {{ formerday }}至{{ today }}</div>
                    </div>
                </div>

                <div v-for="item in itemList" class="box-item" v-if="item.show">
                    <div class="item">
                        <div class="title">
                            <font-awesome-icon :icon=item.logo size="1x" style="color:firebrick" /> {{ item.name }}
                        </div>
                        <div class='content'><span :style="'display:' + (item.num > 0 ? '' : 'none') + ';'">{{ item.num
                        }}</span>
                            <span :style="'font-size:20px;display:' + (item.num > 0 ? '' : 'none') + ';'">次</span>
                            <span :style="'font-size:18px;display:' + (item.num > 0 ? 'none' : '') + ';'">无</span>
                        </div>
                        <div class='content'
                            :style="'color:' + (item.abnormal > 0 ? 'firebrick' : 'black') + ';visibility:' + (item.num > 0 ? '' : 'hidden') + ';'">
                            <el-popover popper-class="popperstyle" placement="top-start" title="异常项内容" width="400"
                                trigger="hover">
                                <el-button slot="reference" class="tagStyle"
                                    :style="'color:' + (item.abnormal > 0 ? 'white' : 'black') + ';background-color:' + (item.abnormal > 0 ? 'firebrick' : 'white') + ';'">异常项</el-button>
                                <slot>{{ item.hover }}</slot>
                            </el-popover>
                            {{ item.abnormal }}<span style="font-size:20px">项</span>
                        </div>
                        <div style="text-align: right;">
                            <el-link class="more" @click="More(item.name)">更多▶</el-link>
                        </div>
                    </div>
                </div>
                <div class="item_tips">
                    <div class="title_tips">
                        今日健康小提醒
                    </div>
                    <div class='content_tips'>
                        <span style="font-size:18px">孕期不宜过度咸食:
                            有些孕妇由于饮食习惯嗜好威食，尤其是北方居民较严重，多咸食。现代医学研究认为，吃盐量与高血压率发病有一定关系，食盐摄入越多，高血压病的发病率也越高。</span>
                    </div>
                    <div style="text-align: right;">
                        <el-link class="more" @click="Tips()">孕期知识▶</el-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- </el-tab-pane>
        <el-tab-pane label="孕婴专项">

        </el-tab-pane>
    </el-tabs> -->
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tips from './tips/pregnant_tips.vue'
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            today: '',
            formerday: '',
            day: '',
            itemList: [],
            nameList: ['心电检测', '24小时Holter', '八导联', '八导联Holter', '尿液分析仪', '血氧检测仪', '血糖检测仪', '血脂检测仪', '血压检测仪', '体脂检测仪', '基础体温', '三合一检测仪', '胎心仪', '生化分析仪'],
            logoList: ['fa-solid fa-heart-pulse', 'fa-solid fa-circle-h', 'fa-solid fa-file-waveform', 'fa-solid fa-square-h',
                'fa-solid fa-vial', 'fa-regular fa-circle', 'fa-solid fa-hard-drive', 'fa-solid fa-droplet',
                'fa-solid fa-stethoscope', 'fa-solid fa-weight-scale', 'fa-solid fa-temperature-three-quarters', 'fa-solid fa-cubes',
                'fa-solid fa-person-breastfeeding', 'fa-solid fa-flask'],
            numList: [],
            hoverList: [],
            abnormalList: [],
            showList: [true, false, false, false, true, true, true, false, true, true, true, false, true, false,],
            chooseItem: false,
            PregnancyWeek: 13,
            // numList: '',
            // singo_abnormal: '',
            // hover_singo: '',
            // num_singoHolter: '',
            // dyanamicsingo_abnormal: '',
            // hover_singoHolter: '',
            identity: '',
        };
    },
    //监听属性 类似于data概念
    computed: {

    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        More(name) {
            if (name == '心电检测') {
                this.$router.push({ name: 'HistorySingo' })
            } else if (name == '24小时Holter') {
                this.$router.push({ name: 'HistorySingoHolter' })
            } else if (name == '八导联') {
                this.$router.push({ name: 'HistoryEight' })
            } else if (name == '八导联Holter') {
                this.$router.push({ name: 'HistoryEightHolter' })
            } else if (name == '尿液分析仪') {
                this.$router.push({ name: 'HistoryUrine' })
            } else if (name == '血氧检测仪') {
                this.$router.push({ name: 'HistoryBloodOxygen' })
            } else if (name == '血糖检测仪') {
                this.$router.push({ name: 'HistoryBloodGlucose' })
            } else if (name == '血脂检测仪') {
                this.$router.push({ name: 'HistoryBloodFat' })
            } else if (name == '血压检测仪') {
                this.$router.push({ name: 'HistoryBloodPressure' })
            } else if (name == '体脂检测仪') {
                this.$router.push({ name: 'HistoryBodyFat' })
            } else if (name == '基础体温') {
                this.$router.push({ name: 'HistoryTem' })
            } else if (name == '三合一检测仪') {
                this.$router.push({ name: 'HistoryThreeInOne' })
            } else if (name == '胎心仪') {
                this.$router.push({ name: 'HistoryBaby' })
            } else if (name == '生化分析仪') {
                this.$router.push({ name: 'HistoryBio' })
            }
        },
        // singoMore() {
        //     this.$router.push({ name: 'HistorySingo' })
        // },
        // singoHolterMore() {
        //     this.$router.push({ name: 'HistorySingoHolter' })
        // },
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return MM + '-' + d
        },
        Weekyday(time) {
            let y = time.getDay();
            var b = "";
            switch (y) {
                case 0:
                    b = "日";
                    break;
                case 1:
                    b = "一";
                    break;
                case 2:
                    b = "二";
                    break;
                case 3:
                    b = "三";
                    break;
                case 4:
                    b = "四";
                    break;
                case 5:
                    b = "五";
                    break;
                default:
                    b = "六";
            }
            return b
        },
        getToday() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 7);
            this.year_today = today.getFullYear()
            this.year_formerday = formerday.getFullYear()
            this.today = this.TimeProcess(today)
            this.formerday = this.TimeProcess(formerday)
            this.day = this.Weekyday(today)
        },
        getData() {
            this.getToday()
            var abnormalName = ['窦性停搏', '房颤', '交界性逸博', '房性早搏', '交界性早搏', '其他异常', '室上性心动过速', '室性心动过速', '室性逸博', '室性早搏', '心动过缓', '心动过速', '心律不齐']

            this.Year_today = this.year_today + '-' + this.today
            this.Year_formerday = this.year_today + '-' + this.formerday
            console.log(this.formerday)

            // 静态单导
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg/getConclusionCountByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {

                    var abnormalItem = []
                    this.staticsingo = data.conclusions
                    // console.log(this.staticsingo)
                    // console.log(this.staticsingo[0])
                    this.itemList[0].num = this.staticsingo.length
                    if (this.itemList[0].num != 0) {
                        var staticsingo = JSON.parse(this.staticsingo[0]) // 最近一次的疾病项结论

                        this.itemList[0].abnormal = Number(staticsingo.dxtb) + Number(staticsingo.fc) + Number(staticsingo.fxyb)
                            + Number(staticsingo.fxzb) + Number(staticsingo.jjxzb) + Number(staticsingo.qtyc) + Number(staticsingo.ssxxdgs)
                            + Number(staticsingo.sxxdgs) + Number(staticsingo.sxyb) + Number(staticsingo.sxzb) + Number(staticsingo.xdgh)
                            + Number(staticsingo.xdgs) + Number(staticsingo.xlbq)
                        var count = 0
                        for (let i in staticsingo) {
                            if (i != 'date' && i != 'xinlv' && staticsingo[i] != 0) {
                                if (i != 'xlbq') {
                                    abnormalItem.push(abnormalName[count - 1])
                                }
                                else {
                                    abnormalItem.push(abnormalName[12])
                                }

                            }
                            count++
                        }
                        if (this.itemList[0].abnormal != 0) {
                            this.itemList[0].hover = abnormalItem + '疑似度过高，请及时就医！'
                        }
                        else (
                            this.itemList[0].hover = '最近一次检测无异常项，请继续保持！'
                        )

                    }
                    else (
                        this.itemList[0].abnormal = 0,
                        this.itemList[0].hover = '最近一次检测无异常项，请继续保持！'
                    )


                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            //动态单导
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg/getConclusionCountByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data)
                    var abnormalItem = []
                    this.dyanamicsingo = data.conclusions
                    this.itemList[1].num = this.dyanamicsingo.length
                    if (this.numList[1] != 0) {
                        var dyanamicsingo = JSON.parse(this.dyanamicsingo[0])[0] // 只取最新的一条记录的第一段

                        this.itemList[1].abnormal = Number(dyanamicsingo.dxtb) + Number(dyanamicsingo.fc) + Number(dyanamicsingo.fxyb)
                            + Number(dyanamicsingo.fxzb) + Number(dyanamicsingo.jjxzb) + Number(dyanamicsingo.qtyc) + Number(dyanamicsingo.ssxxdgs)
                            + Number(dyanamicsingo.sxxdgs) + Number(dyanamicsingo.sxyb) + Number(dyanamicsingo.sxzb) + Number(dyanamicsingo.xdgh)
                            + Number(dyanamicsingo.xdgs) + Number(dyanamicsingo.xlbq)
                        var count = 0
                        for (let i in dyanamicsingo) {

                            if (i != 'time' && i != 'xinlv' && dyanamicsingo[i] != 0) {
                                if (i != 'xlbq') {
                                    abnormalItem.push(abnormalName[count])
                                }
                                else {
                                    abnormalItem.push(abnormalName[12])
                                }

                            }
                            count++
                        }
                        // console.log(abnormalItem)
                        if (this.itemList[1].abnormal != 0) {
                            this.itemList[1].hover = abnormalItem + '疑似度过高，请及时就医！'
                        }
                        else (
                            this.itemList[1].hover = '最近一次检测无异常项，请继续保持！'
                        )
                    }
                    else (
                        this.itemList[1].abnormal = 0,
                        this.itemList[1].hover = '最近一次检测无异常项，请继续保持！'
                    )
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 2静态八导
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg8/getConclusionByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.static8singo = data.conclusions
                    this.itemList[2].num = this.static8singo.length
                    if (this.itemList[2].num != 0) {
                        var static8singo = JSON.parse(this.static8singo[0]) // 最近一次的疾病项结论
                        var xingouDisList = ['fc', 'fxyb', 'fxzb', 'jjxzb', 'ssxxdgs', 'sxxdgs', 'sxyb', 'sxzb', 'xdgh', 'xdgs', 'xlbq', 'Idfs', 'IIdIfs', 'IIdIIfs', 'zszcdzz', 'yszcdzz', 'zqfz', 'dxyjzhz', 'dprzhz', 'byxyjzhz', 'sttg', 'styd', 'tbxf', 'tbgdyc', 'blxqb']
                        var XingouDisName = ['房颤', '交界性逸博', '房性早搏', '交界性早搏', '室上性心动过速', '室性心动过速', '室性逸博', '室性早搏', '窦性心动过缓', '窦性心动过速', '窦性心律不齐', '房室传导阻滞', '房室传导阻滞', '房室传导阻滞', '室内传导阻滞', '室内传导阻滞', '室内传导阻滞', '预激综合征', '预激综合征', '预激综合征', 'ST段抬高', 'ST段压低', 'T波倒置', 'T波高尖', '病理性Q波']
                        var abnormalItem = []
                        var tmp = []
                        for (var j in static8singo) {
                            let a = xingouDisList.indexOf(j)
                            if (a > -1) {
                                if (static8singo[j] != 0) {
                                    tmp.push(XingouDisName[a])
                                }
                            }
                        }
                        abnormalItem = Array.from(new Set(tmp))
                        this.itemList[2].abnormal = abnormalItem.length
                        if (this.itemList[2].abnormal != 0) {
                            this.itemList[2].hover = abnormalItem + '疑似度过高，请及时就医！'
                        }
                        else (
                            this.itemList[2].hover = '最近一次检测无异常项，请继续保持！'
                        )

                    }
                    else (
                        this.itemList[2].abnormal = 0,
                        this.itemList[2].hover = '最近一次检测无异常项，请继续保持！'
                    )

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 3动态八导
            this.$http({
                url: this.$http.adornUrl("/personal/dynamicecg8/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dynamic8singo = data.conclusions
                    this.itemList[3].num = this.dynamic8singo.length
                    if (this.itemList[3].num != 0) {
                        var dynamic8singo = JSON.parse(this.static8singo[0])[0] // 最近一次的疾病项结论,第一段
                        var xingouDisList = ['fc', 'fxyb', 'fxzb', 'jjxzb', 'ssxxdgs', 'sxxdgs', 'sxyb', 'sxzb', 'xdgh', 'xdgs', 'xlbq', 'Idfs', 'IIdIfs', 'IIdIIfs', 'zszcdzz', 'yszcdzz', 'zqfz', 'dxyjzhz', 'dprzhz', 'byxyjzhz', 'sttg', 'styd', 'tbxf', 'tbgdyc', 'blxqb']
                        var XingouDisName = ['房颤', '交界性逸博', '房性早搏', '交界性早搏', '室上性心动过速', '室性心动过速', '室性逸博', '室性早搏', '窦性心动过缓', '窦性心动过速', '窦性心律不齐', '房室传导阻滞', '房室传导阻滞', '房室传导阻滞', '室内传导阻滞', '室内传导阻滞', '室内传导阻滞', '预激综合征', '预激综合征', '预激综合征', 'ST段抬高', 'ST段压低', 'T波倒置', 'T波高尖', '病理性Q波']
                        var abnormalItem = []
                        var tmp = []
                        for (var j in dynamic8singo) {
                            let a = xingouDisList.indexOf(j)
                            if (a > -1) {
                                if (dynamic8singo[j] != 0) {
                                    tmp.push(XingouDisName[a])
                                }
                            }
                        }
                        abnormalItem = Array.from(new Set(tmp))
                        this.itemList[3].abnormal = abnormalItem.length
                        if (this.itemList[3].abnormal != 0) {
                            this.itemList[3].hover = abnormalItem + '疑似度过高，请及时就医！'
                        }
                        else (
                            this.itemList[3].hover = '最近一次检测无异常项，请继续保持！'
                        )

                    }
                    else (
                        this.itemList[3].abnormal = 0,
                        this.itemList[3].hover = '最近一次检测无异常项，请继续保持！'
                    )

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 4尿液
            this.$http({
                url: this.$http.adornUrl("/personal/personalurine/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[4].num = data.personalUrine.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 5血氧
            this.$http({
                url: this.$http.adornUrl("/personal/personalbloodoxygen/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[5].num = data.personalBloodOxygenEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 6血糖
            this.$http({
                url: this.$http.adornUrl("/personal/personalbloodsugar/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[6].num = data.personalBloodSugarEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 7血脂
            this.$http({
                url: this.$http.adornUrl("/personal/personalbodyfat/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[7].num = data.personalBodyfatEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 8血压
            this.$http({
                url: this.$http.adornUrl("/personal/personalbloodpressure/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[8].num = data.personalBloodPressureEntities.length

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 9体脂
            this.$http({
                url: this.$http.adornUrl("/personal/personalbodyfat/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[9].num = data.personalBodyfatEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 10体温
            this.$http({
                url: this.$http.adornUrl("/personal/personaltemperature/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[10].num = data.personalTemperatureEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 11三合一
            this.$http({
                url: this.$http.adornUrl("/personal/personalthreeinone/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[11].num = data.personalThreeInOne.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 12胎心
            this.$http({
                url: this.$http.adornUrl("/personal/personalfhr/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[12].num = data.personalFhrEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // 13生化分析
            this.$http({
                url: this.$http.adornUrl("/personal/personalbiochemicalanalyzer/listByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.itemList[13].num = data.personalBiochemicalAnalyzerEntities.length
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
            // this.$http({
            //     url: this.$http.adornUrl("/personal/dynamicecg/getConclusionCountByTime"),
            //     method: "post",
            //     data: this.$http.adornData({
            //         uid: this.$store.state.women_baby.uid, startTime: this.Year_formerday, endTime: this.Year_today
            //     }),
            // }).then(({ data }) => {
            //     if (data && data.code === 0) {

            // } else {
            //         // this.getCaptcha()
            //         this.$message.error(data.msg);
            //     }
            // });

        },
        updateItemShowStatus() {
            this.itemList.forEach(item => {
                if (item.name != '胎心仪' && item.name != '心电检测' && item.name != '尿液分析仪' && item.name != '血氧检测仪' && item.name != '血糖检测仪' && item.name != '血压检测仪' && item.name !='体脂检测仪' && item.name !='基础体温')
                    item.show = this.chooseItem;
            });
            // '心电检测', '24小时Holter', '八导联', '八导联Holter', '尿液分析仪', '血氧检测仪', '血糖检测仪', '血脂检测仪', '血压检测仪', '体脂检测仪', '基础体温', '三合一检测仪', '胎心仪', '生化分析仪'
            // const item = this.itemList.find(item => item.name != '基础体温' || item.name != '胎心仪');
            // console.log('here', item)
            // if (item) {
            //     item.show = this.chooseItem;
            // }
        },
        Tips() {
            this.$router.push({ name: 'pregnant_tips' })
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.identity = this.$store.state.women_baby.identity
        console.log(this.identity)
        for (let i = 0; i < 14; i++) {
            let e = {};
            e.name = this.nameList[i];
            e.logo = this.logoList[i]
            e.num = this.numList[i]
            e.hover = this.hoverList[i];
            e.abnormal = this.abnormalList[i];
            e.show = this.showList[i];
            this.itemList.push(e);
        }
        console.log(this.itemList)
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        if(this.identity=='1'){
            this.getToday()
            this.getData();
        }

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() {

    }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.el-col-lg-4-8 {
    border-radius: 4px;
    width: 18.5%;
    height: 180px;
    margin: 6px
}

.box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.box-item {
    flex: 0 0 20%;
    min-height: 180px;
}

.item-first {
    width: 95%;
    background: #D9D9D9;
    padding: 15px;
    border-radius: 15px;
    height: 90%
}

.item {
    width: 95%;
    background: #EAEAEA;
    padding: 15px;
    border-radius: 15px;
    height: 90%
}

.item_tips {
    width: 95%;
    background: #fdf0f0;
    padding: 15px;
    border-radius: 15px;
    height: 90%
}


.bg-lightgray {
    background: #EAEAEA;
}

.bg-gray {
    background: #D9D9D9;
}

.grid-content {
    border-radius: 15px;
    min-height: 170px;

}

.title {
    color: black;
    font-weight: bold;
    height: 40px;
    font-size: 23px;
}

.title_tips {
    color: black;
    font-weight: bold;
    height: 40px;
    font-size: 23px;
}

.content {
    color: black;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    height: 35px;
}

.content_tips {
    color: black;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    /* text-align: center; */
    height: 35px;
}

.tagStyle {
    color: black;
    border-radius: 8px;
    padding: 6px;
    margin: 5px;
    font-size: 20px;
    border: none;
    background-color: white;
    width: 80px;
}

.more {
    margin-top: 15px;

    color: royalblue
}
</style>
<style>
.popperstyle .el-popover__title {
    font-size: 20px;
}

.popperstyle {
    font-size: 18px;
}</style>